import { useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as React from "react";

import { WithPeriodTitleTooltip } from "~/components/core/FilterToolbar/FilterToolbar";
import styled from "~/components/core/styled";
import { PERIOD } from "~/constants/dates";
import { Period } from "~/declarations/filters";

interface Props {
  periods?: Period[];
  selectedTab?: PERIOD;
  onSelectTab: (
    periodUnit: Period
  ) => (event: React.MouseEvent<HTMLDivElement>) => void;
}

const FilterToolbarTabs: React.FC<Props> = ({
  periods,
  selectedTab,
  onSelectTab
}: Props): JSX.Element | null => {
  const theme = useTheme();
  const mobileView = useMediaQuery<boolean>(theme.breakpoints.down("xs"));

  if (periods) {
    return (
      <StyledTabs
        value={selectedTab}
        indicatorColor="primary"
        orientation={mobileView ? "vertical" : "horizontal"}
      >
        {periods.map(
          (periodUnit): JSX.Element => (
            <PeriodTab
              key={periodUnit.period}
              label={
                <WithPeriodTitleTooltip period={periodUnit.period}>
                  <span>{periodUnit.title}</span>
                </WithPeriodTitleTooltip>
              }
              value={periodUnit.period}
              onClick={onSelectTab(periodUnit)}
            />
          )
        )}
      </StyledTabs>
    );
  }

  return null;
};

export default FilterToolbarTabs;

const StyledTabs = styled(Tabs)`
  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    margin-bottom: 10px;
  }
`;

const PeriodTab = styled(Tab)`
  min-width: 100px;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    display: flex;
    justify-content: flex-start;

    .MuiTab-wrapper {
      width: auto;
    }
  }
`;
