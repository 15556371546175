import Drawer from "@material-ui/core/Drawer";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as React from "react";

import styled from "~/components/core/styled";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";

interface Props {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

function FilterToolbarAdaptiveContent(
  { children, className }: Props,
  ref: React.Ref<HTMLSpanElement>
): JSX.Element {
  const theme = useTheme();
  const mobileView = useMediaQuery<boolean>(theme.breakpoints.down("xs"));
  const { filtersDrawerOpen, onToggleFiltersDrawer } = React.useContext(
    AccountLayoutContext
  );

  if (!mobileView) {
    return <DesktopWrapper ref={ref}>{children}</DesktopWrapper>;
  }

  return (
    <Drawer
      ref={ref}
      open={filtersDrawerOpen}
      anchor="right"
      className={className}
      onClose={onToggleFiltersDrawer}
    >
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  );
}

export default React.forwardRef(FilterToolbarAdaptiveContent);

const DesktopWrapper = styled.nav`
  align-items: center;
  background-color: ${({ theme }): string => theme.palette.common.white};
  border-bottom: 1px solid ${({ theme }): string => theme.palette.grey[400]};
  display: flex;
  justify-content: space-between;
  margin: 0 -${({ theme }): number => theme.layouts.account.content.padding}px;
  min-height: ${({ theme }): number =>
    theme.layouts.account.filterToolbar.height}px;
  overflow: hidden;
  padding: 0 ${({ theme }): number => theme.layouts.account.content.padding}px;
  position: sticky;
  top: 0;
  z-index: ${({ theme }): number => theme.zIndex.mobileStepper};

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    margin: 0;
    padding: 0;
  }
`;

const DrawerContent = styled.div`
  min-width: 300px;
  padding: 10px;
`;
