import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as React from "react";

import styled from "~/components/core/styled";
import { PERIOD } from "~/constants/dates";
import { Period } from "~/declarations/filters";

interface Props {
  periods?: Period[];
  selectedTab?: PERIOD;
  onSelectTab: (
    periodUnit: Period
  ) => (event: React.MouseEvent<HTMLDivElement>) => void;
}

const FilterToolbarDropdown: React.FC<Props> = ({
  periods,
  selectedTab,
  onSelectTab
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  React.useEffect(() => {
    setAnchorEl(null);
  }, [selectedTab]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClickItem = (item: Period) => (
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    onSelectTab(item)(event);
    setAnchorEl(null);
  };

  const dropdownTitle = React.useMemo(() => {
    return periods?.find(({ period }) => period === selectedTab)?.title;
  }, [periods, selectedTab]);

  return (
    <>
      <StyledButton
        variant="outlined"
        endIcon={<ExpandMoreIcon />}
        onClick={handleOpen}
      >
        {dropdownTitle}
      </StyledButton>
      <StyledPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <StyledList>
          {periods?.map((item: Period) => {
            const { period, title } = item;

            return (
              <ListItem button key={title} onClick={handleClickItem(item)}>
                <ListItemText
                  primary={
                    <FormControlLabel
                      value={period}
                      control={
                        <Radio
                          color="primary"
                          checked={period === selectedTab}
                        />
                      }
                      label={<Caption variant="body1">{title}</Caption>}
                    />
                  }
                />
              </ListItem>
            );
          })}
        </StyledList>
      </StyledPopover>
    </>
  );
};

export default FilterToolbarDropdown;

const StyledButton = styled(Button)`
  border-color: ${({ theme }): string => theme.palette.grey[400]};
  border-radius: 22px;
  font-size: 13px;
  height: 38px;
  white-space: nowrap;
`;

const StyledPopover = styled(Popover)`
  margin-top: 8px;
`;

const StyledList = styled(List)`
  > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }): string => theme.palette.grey[200]};
  }
`;

const Caption = styled(Typography)`
  text-transform: uppercase;
`;
